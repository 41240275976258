<template>
  <b-card>
    <div class="form-group row" v-if="row.item.attendee_email">
      <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.NAME") }}</label>
      <div class="col-lg-9 col-xl-6">
        <b-input-group :prepend="row.item.attendee_id.toString()" class="border-light-dark">
          <b-form-input
            readonly
            disabled
            class="form-control form-control-solid text-muted border-light-dark"
            type="text"
            v-model="/* eslint-disable vue/no-mutating-props */ row.item.attendee_name"
          />
        </b-input-group>
      </div>
    </div>

    <div class="form-group row" v-if="row.item.attendee_email">
      <label class="col-xl-3 col-lg-3 col-form-label text-right">{{ $t("GENERAL.EMAIL") }} </label>
      <div class="col-lg-9 col-xl-6">
        <input readonly disabled class="form-control form-control-solid text-muted border-light-dark" type="text" v-model="row.item.attendee_email" />
      </div>
    </div>
    <h2 v-if="!row.item.attendee_email" class="text-center">
      {{ $t("GENERAL.INVITE_EMAIL") }}
    </h2>

    <div class="form-group row" v-if="!row.item.attendee_email">
      <label class="col-xl-3 col-lg-3 col-form-label text-right text-primary">{{ $t("GENERAL.NAME") }}</label>
      <div class="col-lg-9 col-xl-6">
        <input class="form-control border-primary" type="text" v-model="row.item.token_invite_name" />
      </div>
    </div>
    <div class="form-group row" v-if="!row.item.attendee_email">
      <label class="col-xl-3 col-lg-3 col-form-label text-right text-primary">{{ $t("GENERAL.EMAIL") }}</label>
      <div class="col-lg-9 col-xl-6">
        <input class="form-control border-primary" type="text" v-model="row.item.token_invite_email" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label text-right text-primary">{{ $t("GENERAL.GROUP") }}</label>
      <div class="col-lg-9 col-xl-6">
        <vue-select
          v-model="currentTags"
          :options="tagsFullListByContentId(row.item.content_id)"
          label="name"
          class="w-100"
          multiple
          :loading="tagsLoading"
          @option:selected="onSelect"
          @option:deselected="onDeselect"
        />
      </div>
    </div>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end mb-5 mt-2">
          <button class="btn btn-outline-success mx-4" @click="clickCertificate" v-if="row.item.content_has_certificate && row.item.token_progress === 100">
            <i class="fa fa-certificate fa-2x" v-if="!loadingCertificate"></i>
            <i class="fa fa-spinner fa-2x fa-spin" v-if="loadingCertificate"></i>

            {{ $t("GENERAL.CERTIFICATE") }}
          </button>
          <button class="btn btn-outline-danger mx-4" @click="clickReport" v-if="row.item.content_has_report && row.item.token_progress === 100">
            <i class="fa fa-file-pdf fa-2x" v-if="!loadingReport"></i>
            <i class="fa fa-spinner fa-2x fa-spin" v-if="loadingReport"></i>

            {{ $t("GENERAL.REPORT") }}
          </button>

          <button class="btn btn-primary mx-4" @click="inviteAttendee" v-if="!row.item.attendee_id">
            <i class="flaticon2-email"></i>
            {{ $t("GENERAL.SEND_INVITE") }}
          </button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { INVITE_ATTENDEE } from "@/core/services/store/admin-tokens.module";
import TheToaster from "@/core/services/the-toaster";
import { GET_CERTIFICATE, GET_REPORT } from "@/core/services/store/report.module";
import { mapGetters } from "vuex";
import { ADD_TAG_TO_TOKEN, GET_TAGS_FROM_TOKEN, REMOVE_TAG_TO_TOKEN } from "@/core/services/store/admin-token-tags.module";

export default {
  props: {
    row: Object,
  },
  name: "manage-tokens-details",
  data() {
    return {
      loadingCertificate: false,
      loadingReport: false,
      currentTags: [],
      tagsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      tagsFullList: "adminTokenTags/tagsFullList",
      tagsFullListByContentId: "adminTokenTags/tagsFullListByContentId",
    }),
  },
  methods: {
    onDeselect(e) {
      this.tagsLoading = true;
      this.$store.dispatch("adminTokenTags/" + REMOVE_TAG_TO_TOKEN, { tokenId: this.row.item.token_id, tagId: e.id }).then(({ data }) => {
        // let index = this.currentTags.findIndex(function (o) {
        //   return o.id === e.id;
        // });
        // if (index !== -1) this.currentTags.splice(index, 1);
        TheToaster.success("GENERAL.DATA_SAVED", true);
        this.tagsLoading = false;
      });
    },
    onSelect(e) {
      let tag = e[e.length - 1];
      this.tagsLoading = true;
      this.$store.dispatch("adminTokenTags/" + ADD_TAG_TO_TOKEN, { tokenId: this.row.item.token_id, tagId: tag.id }).then(({ data }) => {
        // this.currentTags
        TheToaster.success("GENERAL.DATA_SAVED", true);
        this.tagsLoading = false;
      });
    },
    inviteAttendee() {
      let message = null;
      if (!this.row.item.token_invite_email) {
        message = this.$t("VALIDATION.REQUIRED", { field: this.$t("GENERAL.EMAIL") });
      }
      if (!this.row.item.token_invite_name) {
        message = this.$t("VALIDATION.REQUIRED", { field: this.$t("GENERAL.NAME") });
      }
      if (message) {
        TheToaster.warning(message, false);
        return;
      }

      this.row.toggleDetails();
      this.$store
        .dispatch("adminTokens/" + INVITE_ATTENDEE, {
          email: this.row.item.token_invite_email,
          name: this.row.item.token_invite_name,
          token: this.row.item.token_token,
        })
        .then((response) => {
          TheToaster.success(response.data.message, false);
        });
    },
    clickCertificate() {
      if (this.loadingCertificate) {
        return;
      }
      this.loadingCertificate = true;
      this.$store
        .dispatch("report/" + GET_CERTIFICATE, {
          token: this.row.item.token_token,
          filename: this.$laUtils.slugIt("CERTIFICATE " + this.row.item.content_codename + " " + this.row.item.attendee_name),
        })
        .finally(() => {
          this.loadingCertificate = false;
        });
    },
    clickReport() {
      if (this.loadingReport) {
        return;
      }
      this.loadingReport = true;
      this.$store
        .dispatch("report/" + GET_REPORT, {
          token: this.row.item.token_token,
          filename: this.$laUtils.slugIt("Report " + this.row.item.content_codename + " " + this.row.item.attendee_name),
        })
        .finally(() => {
          this.loadingReport = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("adminTokenTags/" + GET_TAGS_FROM_TOKEN, { id: this.row.item.token_id }).then(({ data }) => {
      data.token_tags.map((i) => this.currentTags.push(i));
      this.tagsLoading = false;
    });
  },
};
</script>
