<template>
  <div class="token-tags">
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.FILTER')"
              label-for="filter-input"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-input id="filter-input" v-model="tagsFilter.search" type="search" :placeholder="$t('GENERAL.FILTER')"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.PER_PAGE')"
              label-for="per-page-select"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-select id="per-page-select" v-model="tagsFilter.perPage" :options="pageOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="12" lg="12" md="12" sm="12" class="my-1">
            <b-form-group
              :label="$tc('GENERAL.CONTENT', 2)"
              label-for="content-select"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-input-group class="">
                <b-form-select id="content-select" v-model="tagsFilter.content" :options="contents" text-field="name" value-field="id"></b-form-select>
                <b-input-group-append>
                  <b-button variant="outline-danger" @click="tagsFilter.content = ''">
                    <i class="fa fa-times"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="d-flex justify-content-start mb-5 mt-2">
              {{ $t("GENERAL.FOUND") }}:
              {{ tagsPagination.total }}
            </div>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end mb-5 mt-2">
              <button class="btn btn-success mr-4" @click="editTag($event, { item: { id: null } })">
                <i class="fa fa-plus"></i>
                {{ $t("GENERAL.NEW") }}
              </button>
              <button class="btn btn-primary" @click="pageClick($event, 1)">
                <i class="fa fa-search"></i>
                {{ $t("GENERAL.SEARCH") }}
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row></b-row>
        <!-- Main table element -->
        <b-row>
          <b-col class="rounded bg-primary p-1 mt-4">
            <b-table
              v-if="showTable"
              class="m-0"
              :items="tags"
              :fields="fields"
              :current-page="1"
              :per-page="tagsFilter.perPage"
              stacked="md"
              show-empty
              :striped="striped"
              :bordered="bordered"
              :borderless="borderless"
              :outlined="outlined"
              :small="small"
              :hover="hover"
              :dark="dark"
              :fixed="fixed"
              :foot-clone="footClone"
              :no-border-collapse="noCollapse"
              :head-variant="headVariant"
              :table-variant="tableVariant"
              thead-class="head-primary"
            >
              <template #cell(id)="row"> ({{ row.value }}) {{ row.item.name }}</template>
              <!--              <template #cell(name)="row">-->
              <!--                {{ row.value }}-->
              <!--              </template>-->
              <template #cell(content_name)="row">
                {{ row.value }}
              </template>

              <template #cell(actions)="row">
                <b-button size="xs" @click="manageTag($event, row)" class="btn btn-outline-success p-2">
                  <i class="fa fa-eye" style="padding: 0 !important"></i>
                </b-button>
                <b-button size="xs" class="btn btn-outline-info ml-1 p-2" @click="editTag($event, row)">
                  <i class="fa fa-edit" style="padding: 0 !important"></i>
                </b-button>
                <b-button size="xs" class="btn btn-danger ml-1 p-2" @click="onRemoveClick($event, row)">
                  <i class="fa fa-trash" style="padding: 0 !important"></i>
                </b-button>
                <b-button size="xs" class="btn btn-outline-danger ml-1 p-2" @click="onReportClick($event, row)">
                  <i class="fa fa-file-pdf" style="padding: 0 !important" v-if="!loadingReport"></i>
                  <i class="fa fa-spin fa-spinner" style="padding: 0 !important" v-else></i>
                </b-button>
                <b-button size="xs" class="btn btn-success ml-1 p-2" @click="onReportPreviewClick($event, row)">
                  <i class="fa fa-search" style="padding: 0 !important" v-if="!loadingReport"></i>
                  <i class="fa fa-spin fa-spinner" style="padding: 0 !important" v-else></i>
                </b-button>
              </template>

              <template #row-details="row">
                <div>
                  <manage-tokens-details :row="row"></manage-tokens-details>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row v-if="tags.length > 0">
          <b-col sm="12" md="12" class="my-1">
            <la-paginator :pagination="tagsPagination" @page-click="pageClick"></la-paginator>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      id="modal-remove"
      :title="infoModal.title"
      @ok="onRemoveOk"
      @cancel="onRemoveCancel"
      :ok-title="$t('GENERAL.CONFIRM')"
      :cancel-title="$t('GENERAL.CANCEL')"
    >
      <p>{{ $t("GENERAL.REMOVE_TAG") }}</p>
    </b-modal>
    <b-modal
      id="modal-report"
      :title="infoModal.title"
      @ok="onReportOk"
      @cancel="onReportCancel"
      :ok-title="$t('GENERAL.CONFIRM')"
      :cancel-title="$t('GENERAL.CANCEL')"
    >
      <p>{{ $t("GENERAL.CREATE_GROUP_REPORT") }}</p>
    </b-modal>

    <b-modal
      id="modal-report-preview"
      :title="infoModal.title"
      @ok="onReportPreviewOk"
      @cancel="onReportPreviewCancel"
      :ok-title="$t('GENERAL.CONFIRM')"
      :cancel-title="$t('GENERAL.CANCEL')"
      size="xl"
      button-size="sm"
    >
      <iframe
        :src="objectURL"
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto"
        class="border-0 min-h-600px min-w-600px"
        v-if="!loadingReport"
      >
      </iframe>
      <i class="fa fa-spin fa-spinner black" style="padding: 0 !important" v-else></i>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="cancel()"> {{ $t("GENERAL.CONFIRM") }}</b-button>
      </template>
    </b-modal>
    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      :ok-title="$t('GENERAL.CONFIRM')"
      :cancel-title="$t('GENERAL.CANCEL')"
      style=""
      size="xl"
      @ok="performSave"
      @cancel="performCancel"
    >
      <div class="modal-lg">
        <b-form-group
          v-if="editingTag.id"
          :label="$t('GENERAL.ID')"
          label-for="filter-input"
          label-cols-sm="4"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="lg"
          class="mb-0"
        >
          <b-form-input
            id="filter-input"
            v-model="editingTag.id"
            type="search"
            :placeholder="$t('GENERAL.ID')"
            :readonly="true"
            :disabled="true"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('GENERAL.NAME')"
          label-for="filter-input"
          label-cols-sm="4"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="lg"
          class="mb-0"
        >
          <b-form-input id="filter-input" v-model="editingTag.name" type="search" :placeholder="$t('GENERAL.NAME')"></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$tc('GENERAL.CONTENT', 2)"
          label-for="content-select"
          label-cols-sm="4"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="lg"
          class="mb-0"
        >
          <b-input-group class="">
            <b-form-select
              id="content-select"
              v-model="editingTag.content_id"
              :options="contents"
              text-field="name"
              :readonly="editingTag.id !== null"
              :disabled="editingTag.id !== null"
              value-field="id"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<style>
.width-200 {
  width: 200px;
}

.head-primary tr th {
  background-color: #6cabfc !important;
}

.modal-dialog-lg {
  max-width: 1000px !important;
}

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LaPaginator from "@/view/pages/components/LaPaginator/LaPaginator";
import ManageTokensDetails from "@/view/pages/tokens/ManageTokens/components/ManageTokensDetails/ManageTokensDetails";
import { CREATE_UPDATE_TAG, GET_DOWLOADABLE_TAG_REPORT, GET_TAG_REPORT, GET_TAGS, REMOVE_TAG } from "@/core/services/store/admin-token-tags.module";
import { GET_CONTENTS } from "@/core/services/store/contents.module";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "token-tags",
  components: { ManageTokensDetails, LaPaginator },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      currentUserPicture: "auth/currentUserPicture",
      contents: "contents/contents",
      tagsPagination: "adminTokenTags/tagsPagination",
      tags: "adminTokenTags/tags",
      tagsFilter: "adminTokenTags/tagsFilter",
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  data() {
    return {
      pageOptions: [20, 30, 50, 100],
      loadingReport: false,
      tableVariants: ["primary", "secondary", "info", "danger", "warning", "success", "light", "dark"],
      striped: true,
      bordered: true,
      objectURL: "",
      borderless: false,
      outlined: false,
      small: true,
      hover: true,
      dark: false,
      fixed: true,
      showTable: true,
      footClone: false,
      headVariant: "dark",
      tableVariant: "secondary",
      noCollapse: false,
      editingTag: {
        id: null,
        name: "",
        content_id: 0,
      },
      fields: [
        {
          key: "id",
          label: this.$t("GENERAL.NAME"),
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "content_name",
          label: this.$t("GENERAL.CONTENT"),
          sortable: true,
          class: "text-left",
        },

        { key: "actions", label: "Actions", class: "width-200" },
      ],

      infoModal: {
        title: "",
        id: "info-modal",
      },
    };
  },
  methods: {
    onRemoveClick(e, row) {
      this.infoModal.title = this.$t("GENERAL.REMOVE") + ": " + row.item.name;
      this.editingTag.id = row.item.id;
      this.editingTag.name = row.item.name;
      this.editingTag.content_id = row.item.content_id;
      this.$root.$emit("bv::show::modal", "modal-remove", e.target);
      // console.log(e);
    },
    onReportClick(e, row) {
      this.infoModal.title = this.$t("GENERAL.REPORT") + ": " + row.item.name;
      this.editingTag.id = row.item.id;
      this.editingTag.name = row.item.name;
      this.editingTag.content_id = row.item.content_id;
      this.$root.$emit("bv::show::modal", "modal-report", e.target);
      // console.log(e);
    },
    onReportPreviewClick(e, row) {
      this.infoModal.title = this.$t("GENERAL.REPORT") + ": " + row.item.name;
      this.editingTag.id = row.item.id;
      this.editingTag.name = row.item.name;
      this.editingTag.content_id = row.item.content_id;
      this.$root.$emit("bv::show::modal", "modal-report-preview", e.target);
      this.loadingReport = true;
      this.$store
        .dispatch("adminTokenTags/" + GET_DOWLOADABLE_TAG_REPORT, this.editingTag)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let URL = window.webkitURL || window.URL;
          this.objectURL = URL.createObjectURL(blob);
          this.loadingReport = false;
        })
        .finally(() => {
          this.loadingReport = false;
        });
      // console.log(e);
    },
    onRemoveOk(e) {
      this.showTable = false;
      this.$store.dispatch("adminTokenTags/" + REMOVE_TAG, this.editingTag).then((response) => {
        this.$forceUpdate();
        this.showTable = true;
      });
      // console.log("ok");
      // this.$root.$emit("bv::show::modal", "modal-remove", e.target);
      // console.log(e);
    },
    onRemoveCancel(e) {
      // console.log("cancel");
      // this.$root.$emit("bv::show::modal", "modal-remove", e.target);
      // console.log(e);
    },
    onReportOk(e) {
      this.loadingReport = true;
      this.$store
        .dispatch("adminTokenTags/" + GET_TAG_REPORT, this.editingTag)
        .then((response) => {
          this.loadingReport = false;
          // this.$forceUpdate();
          this.showTable = true;
        })
        .catch(() => {
          this.loadingReport = false;
        });
      // this.$root.$emit("bv::show::modal", "modal-remove", e.target);
      // console.log(e);
    },
    onReportCancel(e) {
      this.loadingReport = false;
      // console.log("cancel");
      // this.$root.$emit("bv::show::modal", "modal-remove", e.target);
      // console.log(e);
    },
    onReportPreviewOk(e) {
      // console.log("preview ok");
    },
    onReportPreviewCancel(e) {
      // console.log("preview cancel");
    },
    performSave(e) {
      this.$store.dispatch("adminTokenTags/" + CREATE_UPDATE_TAG, this.editingTag);
    },
    performCancel(e) {
      // this.$store.dispatch("adminTokenTags/" + CREATE_UPDATE_TAG, this.editingTag);
    },
    pageClick(event, page) {
      this.$store
        .dispatch("adminTokenTags/" + GET_TAGS, {
          page: page,
          search: this.tagsFilter.search,
          content_id: this.tagsFilter.content,
          per_page: this.tagsFilter.perPage,
        })
        .then(() => {});
    },
    editTag(e, row) {
      this.infoModal.title = row.item.id ? this.$t("GENERAL.EDIT") + `: ${row.item.name}` : this.$t("GENERAL.NEW");
      this.editingTag.id = row.item.id;
      this.editingTag.name = row.item.name;
      this.editingTag.content_id = row.item.content_id;
      this.$root.$emit("bv::show::modal", this.infoModal.id, e.target);
    },

    manageTag(e, r) {
      this.$router.push({ name: "tokens-manage", query: { tag: r.item.id } });
    },
  },

  mounted() {
    if (!this.currentUser.white_label_enabled) {
      this.$router.push({ name: "dashboard" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("GENERAL.GROUPS") }]);
    this.$store.dispatch("contents/" + GET_CONTENTS).then(() => {});
  },
};
</script>
